<template>
  <div class="doctor">
    <el-alert
      title="请在此处配置医生手机端首页快捷入口，拖动调整各个快捷入口的排列先后顺序。"
      type="success"
      :closable="false"
      style="margin: 9px 0 24px;"
    ></el-alert>
    <div class="search-list">
      <el-button type="primary" @click="add('docAdd')">添加</el-button>
    </div>
    <el-table
      :data="tableData"
      row-key="orderNum"
      ref="tableRef"
      style="width: 100%;margin-top:10px"
    >
      <el-table-column prop="date" label="应用排序">
        <template slot-scope="scope">
          <img src="../../assets/icon/icon-15.png" alt="">
        </template>
      </el-table-column>
      <el-table-column prop="navigateName" label="应用名称"></el-table-column>
      <el-table-column prop="address" label="icon图标">
        <template slot-scope="scope">
          <el-image style="width: 50px; height: 50px" :src="scope.row.navigateIcon" fit="fill"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="isDefault" label="入口性质">
        <template
          slot-scope="scope"
        >{{scope.row.isDefault==1?'默认应用':scope.row.isDefault==2?'自定义应用':null}}</template>
      </el-table-column>
      <el-table-column label="应用状态">
        <template slot-scope="scope">{{scope.row.isEnable==1?'启用':'未启用'}}</template>
      </el-table-column>
      <el-table-column label="操作" align="right" header-align="right">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row,'docEdit')" size="small">编辑</el-button>
          <el-button
            v-if="scope.row.isDefault==2"
            type="text"
            @click="del(scope.row)"
            size="small"
            style="color:red"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="800px">
      <DialogView
        :message="message"
        :type="type"
        :orderNum="orderNum"
        :row="row"
        :isShow="dialogVisible"
        @closeDialog="closeDialog"
        @quxiao="closedialog"
      />
    </el-dialog>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import DialogView from "./dialog.vue";
export default {
  components: {
    DialogView,
  },

  data() {
    return {
      type: 1,
      tableData: [], //列表
      dialogVisible: false,
      title: "",
      message: "",
      orderNum: 0,
      row: {},
      oldList: [],
      newList: [],
      newIndexList: [],
    };
  },

  created() {
    this.getList();
  },

  mounted() {
    this.initSortableList();
  },

  methods: {
    initSortableList() {
      let el = this.$refs.tableRef.$el.querySelector(
        ".el-table__body-wrapper tbody"
      );
      //设置配置
      let _this = this;
      Sortable.create(el, {
        animation: 150,
        sort: true,
        draggable: ".el-table__row", // 设置可拖拽行的类名(el-table自带的类名)
        // forceFallback: true,
        onEnd({ newIndex, oldIndex }) {
          let currRow = _this.newIndexList.splice(oldIndex, 1)[0];
          _this.newIndexList.splice(newIndex, 0, currRow);
          const list = _this.newIndexList.map((item) => item);
          list.reverse();

          const promises = [];

          list.forEach((item, index) => {
            item.orderNum = index + 1;
            const promise = new Promise((resolve, reject) => {
              _this
                .$http({
                  url: _this.$http.adornUrl("/teamNavigate/update"),
                  method: "post",
                  params: item,
                })
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                });
            });
            promises.push(promise);
          });

          Promise.all(promises)
            .then(() => {
              // 所有请求都完成
              _this.getList();
            })
            .catch((error) => {
              // 至少一个请求被拒绝
              console.error("发生错误", error);
            });
        },
      });
    },
    getList() {
      this.$http({
        url: this.$http.adornUrl("/teamNavigate/list"),
        method: "get",
        params: {
          teamsId: JSON.parse(localStorage.currentTeam).id,
          type: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.tableData = res.data.data;
          this.newIndexList = this.tableData;
          this.orderNum = this.tableData.length;
        }
      });
    },
    edit(row, message) {
      this.title = "编辑";
      this.message = message;
      this.row = row;
      this.dialogVisible = true;
    },
    add(message) {
      this.title = "添加";
      this.message = message;

      this.dialogVisible = true;
    },
    del(row) {
      this.$confirm("此操作将删除该图标, 是否继续?", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        type: "warning",
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl("/teamNavigate/delete"),
          method: "post",
          params: {
            ids: row.id,
            type: this.type,
          },
        }).then((res) => {
          if (res.data.status) {
            this.$message.success("删除成功");
            this.getList();
          }
        });
      });
    },
    closeDialog() {
      this.dialogVisible = false;
      this.getList();
    },
    closedialog() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-alert--success.is-light {
  background-color: #e6f5ff;
  color: black;
  border: 1px solid #0476F5;
}
</style>